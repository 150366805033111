import { Box, Center } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import theme from 'config/theme';

const TRACK_BG_DARK_STROKE = '#4B5357';
const TRACK_BG_LIGHT_STROKE = theme.colors.gray[200];

type style = 'dark' | 'light';
type styleConfig = {
  bg: string;
  trackBgStroke: string;
};

const STYLES: Record<style, styleConfig> = {
  dark: { bg: 'runway.black', trackBgStroke: TRACK_BG_DARK_STROKE },
  light: { bg: 'runway.white', trackBgStroke: TRACK_BG_LIGHT_STROKE },
};

interface Props {
  children: React.ReactNode;
  style?: style;
}

const RunwayCurvesBackdrop: React.FC<Props> = ({ children, style = 'dark' }) => {
  const s = STYLES[style];
  if (s == null) {
    return null;
  }

  const { trackBgStroke, bg } = s;

  return (
    <Center w="full" h="100vh" bg={bg} position="relative">
      <Box position="absolute" left={0} top={0} width="full" height="full">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1179 850"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1100.5 1334.5L1100.5 748.5C1100.5 527.586 921.414 348.5 700.5 348.5L-127.5 348.5"
            stroke={trackBgStroke}
            strokeWidth="10"
            strokeLinecap="round"
          />
          <path
            d="M-298.5 348.5L287.5 348.5C508.414 348.5 687.5 169.414 687.5 -51.5002L687.5 -879.5"
            stroke={trackBgStroke}
            strokeWidth="10"
          />
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.6, repeat: Infinity, repeatDelay: 4 }}
            d="M1100.5 1334.5L1100.5 748.5C1100.5 527.586 921.414 348.5 700.5 348.5L-127.5 348.5"
            stroke="#FFC655"
            strokeWidth="10"
            strokeLinecap="round"
          />
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.6, repeat: Infinity, repeatDelay: 4 }}
            d="M687.5 -879.5L687.5 -51.5002C687.5 169.414 508.414 348.5 287.5 348.5L-298.5 348.5"
            stroke="#F9A600"
            strokeWidth="10"
            strokeLinecap="round"
          />
        </svg>
      </Box>
      <Box position="relative">{children}</Box>
    </Center>
  );
};

export default RunwayCurvesBackdrop;
