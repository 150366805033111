import { LockIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBoolean,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Analytics from '@segment/analytics-node';
import * as Sentry from '@sentry/nextjs';
import { FirebaseError, getApps, initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { motion } from 'framer-motion';
import { isString, noop } from 'lodash';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import LoadingPage from 'components/LoadingPage/LoadingPage';
import LoggedOutModal from 'components/LoggedOutModal/LoggedOutModal';
import SSOForm from 'components/SSOForm';
import firebaseConfig from 'config/firebase';
import { DEFAULT_COOKIE_OPTS, getCookies, LOGIN_REDIRECT } from 'helpers/cookies';
import { backendUrl, isCypress, isDevelopment, isSSR } from 'helpers/environment';
import { NextRedirect } from 'helpers/next';
import { ssoReadyAuthProvider, xeroAuthProvider } from 'helpers/oauthProviders';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useTriggerRipplingSSO } from 'hooks/useTriggerRipplingSSO';
import { logOutUser } from 'reduxStore/actions/logOutUser';
import { loggedInUserSelector } from 'selectors/loginSelector';
import { RunwayPageContext } from 'types/RunwayPageContext';
import GoogleIcon from 'vectors/Google';
import MicrosoftIcon from 'vectors/Microsoft';
import Rippling from 'vectors/Rippling';
import Xero from 'vectors/Xero';

const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string;
if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}

const LoginErrorModal = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unable to log in</ModalHeader>
        <ModalCloseButton />
        <ModalBody>There was an error logging you in. Please try again.</ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CypressEmailLogin = ({ loginUser }: { loginUser: (token: string) => void }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      signInWithEmailAndPassword(getAuth(), email, password)
        .then(({ user }) => {
          user.getIdToken().then((token) => {
            loginUser(token);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [email, loginUser, password],
  );

  return (
    <Flex direction="column">
      <Text color="runway.white" textStyle="marketing" fontSize="md" fontWeight="semibold">
        You are running in Cypress. Email provider is only available in Cypress.
      </Text>
      <form onSubmit={login}>
        <Input name="email" onChange={(e) => setEmail(e.target.value)} />
        <Input name="password" type="password" onChange={(e) => setPassword(e.target.value)} />
      </form>
    </Flex>
  );
};

interface Props {
  loggedOutReason: string | null;
  shareLinkNonce: string | null;
}

const Login: NextPage<Props> = ({ loggedOutReason, shareLinkNonce }) => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const [showSsoLogin, setShowSsoLogin] = useBoolean(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const loggedInUser = useAppSelector(loggedInUserSelector);
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    const { status, error } = router.query;
    if (status === 'error') {
      dispatch(logOutUser(Array.isArray(error) ? error[0] : error));
    }
  }, [dispatch, router.query]);

  const orgs = loggedInUser?.orgs ?? [];
  const showNoOrgsView = loggedInUser != null && orgs.length === 0;

  const loginUser = useCallback(
    (token: string) => {
      const callback = window.location.href.replace(window.location.search, '');
      const noncePart =
        shareLinkNonce != null ? `&shareLinkNonce=${encodeURIComponent(shareLinkNonce)}` : '';
      window.location.href = `${backendUrl}/auth/login?idToken=${token}&callback=${callback}${noncePart}`;
    },
    [shareLinkNonce],
  );

  const microsoftLoginCallback = useCallback(() => {
    const provider = new OAuthProvider('microsoft.com');
    Sentry.addBreadcrumb({ message: 'signInWthPopup called' });
    signInWithPopup(getAuth(), provider).then(
      (result) => {
        Sentry.addBreadcrumb({
          message: 'Login success called',
          data: { email: result?.user?.email ?? 'unknown' },
        });
        const { user } = result;
        if (user !== null) {
          user.getIdToken().then((token: string) => {
            if (token != null) {
              loginUser(token);
            }
          });
        }
      },
      (reason: FirebaseError) => {
        Sentry.addBreadcrumb({
          message: 'Login error called',
          data: { reason, message: reason.message },
        });
        // do nothing when the user closes the popup themselves
        if ('code' in reason && reason.code.includes('popup-closed-by-user')) {
          return;
        }

        console.error('login error: ', reason.message);
        setLoginError(reason.message);
      },
    );
  }, [loginUser]);

  const googleLoginCallback = useCallback(() => {
    const provider = new GoogleAuthProvider();
    Sentry.addBreadcrumb({ message: 'signInWthPopup called' });
    signInWithPopup(getAuth(), provider).then(
      (result) => {
        Sentry.addBreadcrumb({
          message: 'Login success called',
          data: { email: result?.user?.email ?? 'unknown' },
        });
        const { user } = result;
        if (user !== null) {
          user.getIdToken().then((token: string) => {
            if (token != null) {
              loginUser(token);
            }
          });
        }
      },
      (reason: FirebaseError) => {
        Sentry.addBreadcrumb({
          message: 'Login error called',
          data: { reason, message: reason.message },
        });
        // do nothing when the user closes the popup themselves
        if ('code' in reason && reason.code.includes('popup-closed-by-user')) {
          return;
        }

        console.error('login error: ', reason.message);
        setLoginError(reason.message);
      },
    );
  }, [loginUser]);

  const xeroLoginCallback = useCallback(() => {
    Sentry.addBreadcrumb({ message: 'signInWthPopup called' });
    signInWithPopup(getAuth(), xeroAuthProvider).then(
      (result: UserCredential) => {
        Sentry.addBreadcrumb({
          message: 'Login success called',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          data: { email: result?.user?.email },
        });
        const { user } = result;
        if (user !== null) {
          user.getIdToken().then((token: string) => {
            if (token != null) {
              loginUser(token);
            }
          });
        }
      },
      (reason: FirebaseError) => {
        Sentry.addBreadcrumb({
          message: 'Login error called',
          data: { reason, message: reason.message },
        });
        // do nothing when the user closes the popup themselves
        if ('code' in reason && reason.code.includes('popup-closed-by-user')) {
          return;
        }
        console.error(reason);
        console.error('login error: ', reason.message);
        setLoginError(reason.message);
      },
    );
  }, [loginUser]);

  const ssoLoginCallback = useCallback(
    (externalOrgId: string) => {
      // Set the custom parameter to pass the external org id to the provider
      ssoReadyAuthProvider.setCustomParameters({
        organizationExternalId: externalOrgId,
      });
      Sentry.addBreadcrumb({ message: 'signInWithPopup' });
      signInWithPopup(getAuth(), ssoReadyAuthProvider).then(
        (result: UserCredential) => {
          Sentry.addBreadcrumb({
            message: 'Login success called',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            data: { email: result?.user?.email },
          });
          const { user } = result;
          if (user !== null) {
            user.getIdToken().then((token: string) => {
              if (token != null) {
                loginUser(token);
              }
            });
          }
        },
        (reason: FirebaseError) => {
          Sentry.addBreadcrumb({
            message: 'Login error called',
            data: { reason, message: reason.message },
          });
          // do nothing when the user closes the popup themselves
          if ('code' in reason && reason.code.includes('popup-closed-by-user')) {
            return;
          }
          console.error('login error: ', reason.message);
          setLoginError(reason.message);
        },
      );
    },
    [loginUser],
  );

  const ripplingLoginCallback = useTriggerRipplingSSO();

  // If we already have a users' auth information in local storage, we can log
  // them in automatically. Show the loading page until we have fetched the
  // user's login info from Firebase.
  useEffect(() => {
    if (showNoOrgsView || loggedOutReason != null) {
      setIsLoadingUser(false);
      return noop;
    }

    return onAuthStateChanged(getAuth(), (user) => {
      if (user != null) {
        user.getIdToken().then((token) => {
          if (token != null) {
            loginUser(token);
          } else {
            setIsLoadingUser(false);
          }
        });
      } else {
        setIsLoadingUser(false);
      }
    });
  }, [loginUser, showNoOrgsView, loggedOutReason]);

  const [showMobileWarning, setShowMobileWarning] = useBoolean(isMobile);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [height, setHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length === 0) {
        return;
      }
      const observedHeight = entries[0].contentRect.height;
      setHeight(observedHeight);
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef?.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  if (isLoadingUser) {
    return <LoadingPage />;
  }

  return (
    <>
      {loginError != null && <LoginErrorModal />}
      {showMobileWarning && (
        <LoggedOutModal includeTOS={false}>
          <Text color="runway.white" fontSize="sm" fontWeight="regular" textAlign="center">
            Runway is designed for desktop browsers. <br />
            Some features might not work on mobile.
          </Text>
          <Button onClick={setShowMobileWarning.off} w="full" variant="marketing">
            Continue on mobile anyway
          </Button>
        </LoggedOutModal>
      )}
      {!showMobileWarning && (
        <LoggedOutModal
          includeTOS
          errorContent={
            showNoOrgsView ? (
              <>
                We couldn&apos;t find your organization in Runway. Ask your administrator to invite
                you, or{' '}
                <a href="https://runwayco.typeform.com/to/WWmwTjg1?typeform-source=app.runway.com">
                  <u>sign up for the waitlist</u>
                </a>
                .
              </>
            ) : (
              loggedOutReason
            )
          }
        >
          {loggedInUser == null && showSsoLogin && (
            <Flex
              position="absolute"
              cursor="pointer"
              top={8}
              left={8}
              fontSize="26px"
              fontWeight="400"
              onClick={setShowSsoLogin.off}
              _hover={{ opacity: 0.8 }}
              color="#FDFCFC99"
            >
              {`<--`}
            </Flex>
          )}
          <VStack gap={1}>
            <Text color="runway.white" textStyle="marketing" fontSize="2xl" fontWeight="semibold">
              {loggedInUser == null ? 'Log in to Runway' : 'Runway'}
            </Text>
            <Text
              color="runway.white"
              textStyle="marketing"
              fontSize="sm"
              fontWeight="regular"
              opacity={0.6}
              textAlign="center"
            >
              Business planning, designed for humans.
            </Text>
          </VStack>
          <motion.div
            className="parent"
            layout
            animate={{ height }}
            style={{ overflow: 'hidden', width: '100%' }}
            transition={{ duration: 0.4, ease: [0.25, 0.8, 0.5, 1] }}
          >
            <Box ref={containerRef}>
              {loggedInUser == null && showSsoLogin && (
                <motion.div
                  initial={{ x: '30vw', opacity: 0 }} // Start off-screen to the right
                  animate={{ x: 0, opacity: 1 }} // Slide in and fade in
                  transition={{
                    x: { duration: 0.2, ease: [0.25, 0.8, 0.5, 1], delay: 0.2 },
                    opacity: { duration: 0.5, ease: [0.25, 0.8, 0.5, 1] },
                  }}
                >
                  <SSOForm ssoLoginCallback={ssoLoginCallback} />
                </motion.div>
              )}
              {loggedInUser == null && !showSsoLogin && (
                <motion.div
                  style={{ width: '100%' }}
                  initial={{ opacity: 0, x: '30vw' }} // Start off-screen to the right
                  animate={{ opacity: 1, x: 0 }} // Slide in to its normal position
                  transition={{
                    x: { duration: 0.2, ease: [0.25, 0.8, 0.5, 1], delay: 0.2 },
                    opacity: { duration: 0.5, ease: [0.25, 0.8, 0.5, 1] },
                  }}
                >
                  <VStack gap={4} w="full">
                    <Button
                      onClick={googleLoginCallback}
                      variant="marketing"
                      w="full"
                      justifyContent="space-between"
                      aria-label="Sign in with Google"
                      rightIcon={
                        <GoogleIcon
                          boxSize="2rem"
                          borderRadius="2rem"
                          bg="white"
                          p={2}
                          display="flex"
                        />
                      }
                    >
                      Continue with Google
                    </Button>
                    <Button
                      onClick={ripplingLoginCallback}
                      variant="marketing"
                      w="full"
                      aria-label="Sign in with Rippling"
                      justifyContent="space-between"
                      rightIcon={<Rippling boxSize="2rem" borderRadius="2rem" display="flex" />}
                    >
                      Continue with Rippling
                    </Button>
                    <Button
                      onClick={microsoftLoginCallback}
                      variant="marketing"
                      w="full"
                      justifyContent="space-between"
                      aria-label="Sign in with Microsoft"
                      rightIcon={
                        <Flex
                          borderRadius="2rem"
                          bg="white"
                          p={1}
                          m={0}
                          alignItems="center"
                          justifyContent="center"
                          boxSize="2rem"
                        >
                          <MicrosoftIcon boxSize="1rem" />
                        </Flex>
                      }
                    >
                      Continue with Microsoft
                    </Button>
                    <Button
                      onClick={xeroLoginCallback}
                      aria-label="Sign in with Xero"
                      variant="marketing"
                      w="full"
                      justifyContent="space-between"
                      rightIcon={<Xero boxSize="2rem" borderRadius="2rem" display="flex" />}
                    >
                      Continue with Xero
                    </Button>
                    <Divider borderColor="rgba(180, 175, 175, 0.1)" />
                    <Button
                      w="full"
                      variant="ghost"
                      color="white"
                      borderRadius="16px"
                      h="48px"
                      _hover={{ bgColor: 'runway.yellow', color: 'black', border: 'none' }}
                      leftIcon={<LockIcon />}
                      onClick={setShowSsoLogin.on}
                    >
                      Sign in with SSO
                    </Button>
                  </VStack>
                </motion.div>
              )}
              {isCypress() && <CypressEmailLogin loginUser={loginUser} />}
              {loggedInUser != null && (
                <>
                  <Divider />
                  <Flex
                    fontSize="xxs"
                    fontWeight="medium"
                    w="full"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text>Logged in as {loggedInUser.name}</Text>
                    <Button
                      onClick={() => {
                        dispatch(logOutUser());
                      }}
                      size="sm"
                      fontSize="xxs"
                      fontWeight="semibold"
                    >
                      Log out
                    </Button>
                  </Flex>
                </>
              )}
            </Box>
          </motion.div>
        </LoggedOutModal>
      )}
    </>
  );
};

Login.getInitialProps = (ctx: RunwayPageContext): Props => {
  const { reduxStore } = ctx;
  const loggedInUser = loggedInUserSelector(reduxStore.getState());

  const loggedOutReason = isString(ctx.query.logoutReason) ? ctx.query.logoutReason : null;
  const shareLinkNonce = isString(ctx.query.shareNonce) ? ctx.query.shareNonce : null;

  const props = {
    loggedOutReason,
    shareLinkNonce,
  };

  if (!loggedInUser) {
    return props;
  }

  if (isSSR() && !isDevelopment) {
    const { id } = loggedInUser;
    const analytics = new Analytics({ writeKey: segmentWriteKey });
    if (loggedInUser.isNew) {
      analytics.track({ userId: id, event: 'Sign Up' });
    } else {
      analytics.track({ userId: id, event: 'Login' });
    }

    if (loggedInUser.orgs == null || loggedInUser.orgs.length === 0) {
      analytics.track({ userId: id, event: 'Sign Up Waiting Page Loaded' });
    }
  }

  const orgs = loggedInUser?.orgs ?? [];
  const loginRedirect = getCookies(ctx).login_redirect;
  if (loginRedirect != null) {
    destroyCookie(ctx, LOGIN_REDIRECT, DEFAULT_COOKIE_OPTS);
    NextRedirect(ctx, loginRedirect);
  } else if (orgs.length > 0) {
    NextRedirect(ctx, '/');
  }

  return props;
};

export default Login;
