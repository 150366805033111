import { Progress } from '@chakra-ui/react';
import React from 'react';

import { SquircleRunwayLogo } from 'components/RunwayLogo/RunwayLogo';

const LoadingPage: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="electron-drag"
    >
      <div>
        <SquircleRunwayLogo />
        <Progress mt={3} size="xs" isIndeterminate isAnimated />
      </div>
    </div>
  );
};

export default React.memo(LoadingPage);
